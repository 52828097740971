import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { getTenantCode } from "../../../util";

const VALUES_BY_TENANT = {
    "L337": {
        modes: [
            { id: 1, value: "Ritiro presso l'ufficio del Comune" },
            { id: 2, value: "Invio tramite PEC" },
            // { id: 3, value: "Invio tramite raccomandata" }
        ],
        declarations: [
            { value: "1-di conoscere le sanzioni amministrative e penali previste dagli artt. 75 e 76 del D.P.R. 445/2000, “Testo unico delle disposizioni legislative e regolamentari in materia di documentazione amministrativa" },
            { value: "2-di voler ricevere quanto richiesto personalmente presso l’ufficio competente del Comune, oppure all'indirizzo di posta elettronica certificata (PEC) indicato nella richiesta. Il rilascio di dati o documenti in formato elettronico o cartaceo è gratuito, salvo il rimborso del costo effettivamente sostenuto e documentato dall’amministrazione per la riproduzione su supporti materiali" }
        ],
        extra_declarations: [
            { id: 1, value: "di prendere visione per esame" },
            { id: 2, value: "di estrarre copia semplice (in carta libera) senza allegati" },
            { id: 3, value: "di estrarre copia semplice (in carta libera) con allegati" },
            { id: 4, value: "di estrarre copia conforme (necessita di marca da bollo da 16,00 euro)" },
        ],
        customer_types: ["Richiedente", "Delegato"],
        extra_fields: [
            {
                id: "chiede_extra",
                defaultValue: ""
            },
            {
                id: "indirizzo_pec",
                defaultValue: ""
            },
            {
                id: "marca_bollo",
                defaultValue: {}
            },
        ]
    },
    "default": {
        modes: [
            { id: 1, value: "Ritiro presso l'ufficio del Comune" },
            { id: 2, value: "Invio tramite email" },
            { id: 3, value: "Invio tramite raccomandata" }
        ],
        declarations: [
            { value: "1-di conoscere le sanzioni amministrative e penali previste dagli artt. 75 e 76 del D.P.R. 445/2000, “Testo unico delle disposizioni legislative e regolamentari in materia di documentazione amministrativa" },
            { value: "2-di voler ricevere quanto richiesto personalmente presso l’ufficio competente del Comune, oppure al proprio indirizzo di posta elettronica, oppure che gli atti siano inviati all’indirizzo di spedizione indicato mediante raccomandata con avviso di ricevimento con spesa a proprio carico. Il rilascio di dati o documenti in formato elettronico o cartaceo è gratuito, salvo il rimborso del costo effettivamente sostenuto e documentato dall’amministrazione per la riproduzione su supporti materiali" }
        ]
    }
}

const getValues = () => {
    const tenantCode = getTenantCode();
    return VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"]
}

const getExtraFields = (context) => {
    const tenantCode = getTenantCode();
    const values = VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"];
    if(context && values?.extra_fields){
        let fieldsObj = {}
        values?.extra_fields?.map(field => {
            fieldsObj[field?.id] = context?.[field?.id] || field?.defaultValue || null;
        })
        // console.log("fieldsObj", fieldsObj)
        return fieldsObj;
    }

    return {}
}

export const useAccess = (parentSaveFunc, config, parentSendFunc) => {
    /********************* hooks go down here *********************/
    const ctxApplicant = useSelector(state => state.stepSlice.data.access?.richiedente);
    const ctxDelegate = useSelector(state => state.stepSlice.data.access?.delegato);
    const ctxSpecific = useSelector(state => state.stepSlice.data.preferenze?.accessi);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);
    const isStopped = useSelector(state => state.stepSlice.data.stopped);
    const ctx = useSelector(state => state.stepSlice.data?.access);
    const dispatcher = useDispatch();
    /********************* state go down here *********************/
    const [data, setData] = useState({
        richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true, type: ctxApplicant[0]?.type || (getValues()?.customer_types ? {type: "Richiedente"} : "") }] : [],
        delegato: ctxDelegate?.length > 0 ? [{ ...ctxDelegate[0], selected: true }] : [],
        tipologia: ctxSpecific?.tipologia || "",
        modalita: ctxSpecific?.modalita || "",
        motivo: ctxSpecific?.motivo || "",
        chiede: ctxSpecific?.chiede || {
            documento: "",
            informazione: "",
            dato: ""
        },
        dichiaro: ctxSpecific?.dichiaro || [],
        indirizzo_spedizione: ctxSpecific?.indirizzo_spedizione || "",
        ...getExtraFields(ctxSpecific)
    });
    const [values, setValues] = useState({
        ...getValues()
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    /********************* methods go down here *********************/
    ////////////////////// get functions //////////////////////
    const getAccessFields = () => {
        let type = data?.richiedente?.[0]?.type?.type

        switch(type){
            case "Richiedente":
                return ["richiedente", "tipologia", "modalita", "chiede", "motivo", "dichiaro", "chiede_extra", "preferenze"]
            case "Delegato":
                return ["richiedente", "delegato", "tipologia", "modalita", "chiede", "motivo", "dichiaro", "chiede_extra", "preferenze"]
            default:
                return ["richiedente", "tipologia", "modalita", "chiede", "motivo", "dichiaro", "preferenze"]
        }
    }

    ////////////////////// validate functions //////////////////////
    const isValidField = (id) => {
        switch(id){
            case "richiedente":
                return data[id]?.length > 0 ? true : false;
            case "delegato":
                return data[id]?.length > 0 ? true : false;
            case "modalita":
                switch(data[id]){
                    case "Invio tramite PEC":
                        return !data?.indirizzo_pec ? false : true;
                    case "Invio tramite raccomandata":
                        return !data?.indirizzo_spedizione ? false : true;
                    default:
                        return data[id] ? true : false
                }
            case "chiede":
                const obj = data[id];
                return obj?.documento && obj?.informazione && obj?.dato ? true : false
            case "chiede_extra":
                const shouldShowMarca = values?.extra_declarations ? data?.chiede_extra === values?.extra_declarations?.[3]?.value : false;
                if(shouldShowMarca)
                    return shouldShowMarca && (data?.marca_bollo?.numero?.length === 14 && data?.marca_bollo?.data) ? true : false
                else return data[id] ? true : false
            case "dichiaro":
                return data?.dichiaro?.length === 2 ? true : false 
            // case "chiede_extra":
            //     return data?.chiede_extra?.length === 4 ? true : false 
            case "preferenze":
                return true
            default:
                return data[id] ? true : false
        }
    }

    const checkFields = (fields) => {
        // console.log("checkFields", fields)
        if(fields?.length > 0){
            let isValid = true;
            for(let key in fields){
                isValid = typeof isValidField(fields[key]) !== "object" && isValidField(fields[key]) === true;
                // console.log("field", fields[key], isValid)
                if(!isValid) break;
            }
            return isValid;
        }

        return false;
    }

    const isValidData = (isSendRequest) => {
        const tipologia = "";
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(tipologia){
            // case "BA3":
            //     break;
            default:
                if(isSendRequest) requiredFields = getAccessFields()
                else requiredFields = []
        }

        if(requiredFields?.length > 0) return checkFields(requiredFields)        

        return true;
    }

    const checkErrors = () => {
        let newError = {...error}

        const fields = getAccessFields() || []
        fields.map(field => {
            let isValid = isValidField(field)
            newError[field] = typeof isValid === "object" ? isValid : !isValid
        })
        
        setError(newError);
    }

    const isValidStep = (stepId) => {
        switch(stepId){
            case "general":
                let type = data?.richiedente?.[0]?.type?.type
                // console.log("is valid step", data?.delegato, error);
                if(type === "Richiedente"){
                    return (error?.richiedente || isStopped) ? false : true
                }
                else if(type === "Delegato"){
                    return (error?.richiedente || error?.delegato) ? false : true
                }
                else return (error?.richiedente || isStopped) ? false : true
            case "specific":
            case "summary":
                const tenantCode = getTenantCode();
                switch(tenantCode){
                    case "L337":
                        return (error?.richiedente 
                            || error?.tipologia 
                            || error?.modalita 
                            || error?.chiede 
                            || error?.motivo
                            || error?.dichiaro
                            || error?.chiede_extra
                            || isStopped) ? false : true
                    default:
                        return (error?.richiedente 
                            || error?.tipologia 
                            || error?.modalita 
                            || error?.chiede 
                            || error?.motivo
                            || error?.dichiaro
                            || isStopped) ? false : true
                }
            default:
                return false;
        }
    }
    ////////////////////// UPDATE //////////////////////
    const parseField = (id, newData) => {
        switch(id){
            case "richiedente":
                return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
            case "delegato":
                return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
            default:
                return newData?.data || null
        }
    }

    const update = (newData) => {
        // console.log("useBonus update", newData, typeof newData === "object", Array.isArray(newData));
        if(typeof newData === "object" && !Array.isArray(newData)){
            const id = newData?.id?.toLowerCase();
            const internalId = newData?.internalId || null;
            if(id){
                if(internalId){
                    setData(oldData => ({
                        ...oldData,
                        [id]: {
                            ...(data?.[id] || {}),
                            [internalId]: newData?.data || null
                        }
                    }))
                }
                else{
                    setData(oldData => ({
                        ...oldData,
                        [id]: parseField(id, newData)
                    }))
                }
            }
        }
        else if (Array.isArray(newData)){
            let updatedData = {...data};
            newData.map(newDataItem => {
                const id = newDataItem?.id?.toLowerCase();
                const internalId = newDataItem?.internalId || null;
                if(id){
                    if(internalId){
                        updatedData = {
                            ...updatedData,
                            [id]: {
                                ...(updatedData?.[id] || {}),
                                [internalId]: newDataItem?.data || null
                            }
                        }
                    }
                    else{
                        updatedData = {
                            ...updatedData,
                            [id]: parseField(id, newDataItem)
                        }
                    }
                }
            })
            // console.log("updatedData", updatedData)
            setData(updatedData);
        }
    }

    const updateField = (e, fieldId, isInternal) => {
        // console.log("e", e?.target?.id, e?.target?.value);
        const id = e?.target?.id?.toLowerCase();
        const value = e?.target?.value;
        if((fieldId && id && isInternal) || (fieldId && !isInternal)){
            update({
                id: isInternal ? fieldId : id,
                internalId: isInternal ? id : null,
                data: value
            })
        }
    }

    const updatePrefs = (newPreferences) => {
        // console.log("newPreferences", newPreferences)
        const prefsFields = Object.keys(getPrefs());
        if(prefsFields?.length > 0 && newPreferences?.data?.accessi){
            let updatedPrefs = {};
            Object.keys(newPreferences?.data?.accessi)?.map(key => {
                if(prefsFields?.indexOf(key) !== -1)
                    updatedPrefs[key] = newPreferences?.data?.accessi[key];
            })
            // console.log("new preferences", newPreferences)
            setData({
                ...data,
                ...updatedPrefs
            })
        }
    }
    ////////////////////// SAVE //////////////////////
    const getDefaultPrefs = () => {
        return{
            tipologia: data?.tipologia || "",
            modalita: data?.modalita || "",
            chiede: data?.chiede || {},
            dichiaro: data?.dichiaro || [],
            motivo: data?.motivo || "",
        }
    }
    const getPrefs = (isSend) => {
        const tenantCode = getTenantCode();
        switch(tenantCode){
            case "L337":
                const shouldShowMarca = values?.extra_declarations ? data?.chiede_extra === values?.extra_declarations?.[3]?.value : false;
                return {
                    ...getDefaultPrefs(),
                    //// CHIEDE extra ////
                    chiede_extra: data?.chiede_extra || "",
                    //// PEC ////
                    ...((data?.modalita === "Invio tramite PEC" || !isSend) && 
                    {indirizzo_pec: data?.indirizzo_pec || "",}),
                    //// MARCA ////
                    ...((shouldShowMarca || !isSend) && {marca_bollo: data?.marca_bollo || {}}),
                }
            default:
                return {
                    ...getDefaultPrefs(),
                    ...((data?.modalita === "Invio tramite raccomandata" || !isSend) && {indirizzo_spedizione: data?.indirizzo_spedizione || ""}),
                }
        }
    }
    const savePrefs = () => {
        // console.log("get prefs", getPrefs())
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "accessi", data: {
            ...getPrefs()
        } }));
    }

    const save = async (isSendRequest) => {
        const fields = getAccessFields() || []
        let saveData = {}
        fields.map(field => {
            let isValid = typeof isValidField(field) !== "object" && isValidField(field) === true;
            // console.log("is valid data", field, isValid)
            if(data[field] && isValid) saveData[field] = data[field];
        })

        const prefs = getPrefs(true)
        if(Object.keys(prefs)?.length > 0){
            saveData.preferenze = {
                accessi: {...prefs}
            }
        }

        // console.log("fields", saveData)
        // console.log("save data", saveData)
        let isSend = typeof isSendRequest === "boolean" ? isSendRequest : false;
        if(isValidData(isSend) && ((parentSaveFunc && !isSend) || (parentSendFunc && isSend))){
            dispatcher(stepSlice.actions.dynamicSave({ id: "Access", data: {...saveData} }));
            savePrefs();
            setLoading(true);
            if(isSend){
                await parentSendFunc(saveData);
            }
            else{
                await parentSaveFunc(saveData);
            }
            setLoading(false);
        }
    }

    const clear = () => {
        setData({})
    }

    /********************* useEffects go down here *********************/
    useEffect(() => {
        console.log("useAccess", data);
        dispatcher(stepSlice.actions.dynamicSave({ id: "Access", data: data || {} }));
        savePrefs();
        checkErrors();
    }, [data]);

    useEffect(() => {
        // console.log("useAccess error", error, data);
    }, [error])

    useEffect(() => {
        checkErrors();
    }, [])

    return {
        data,        // current data of the service request
        values,      // values to populate fields such as dropdowns
        loading,     // true = is saving request 
        error,       // error flag by data[id] eg. data["richiedente"] is invalid -> error.richiedente = true
        getPrefs,
        save,        // function to save the current service request
        savePrefs,   // function to save prefs into the store
        updateField,
        update,      // function to update the data object
        updatePrefs,
        isValidStep, // function to check <stepId> data -> useful for nextButton disable status
    }
}