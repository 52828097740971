import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { getTenantCode } from "../../../util";

const DEFAULT_NOBONUS_VALUE = "Nessun bonus attivo al momento";

const VALUES_BY_TENANT = {
    "L337": {
        declarations: [
            { value: "1-Di voler procedere all’acquisto e/o alla ristrutturazione di una prima casa, ovvero di un’abitazione con categoria catastale diversa da A1, A8 e A9;" },
            { value: "2-Che nel caso di interventi di ristrutturazione edilizia, gli stessi interessano l’abitazione e non le parti comuni;" },
            { value: "3-Di impegnarsi a concludere gli eventuali interventi di ristrutturazione entro 36 mesi dalla data di riconoscimento del contributo;" },
            { value: "4-Di impegnarsi entro 18 mesi dall’acquisto dell’abitazione o dalla data di ultimazione dei lavori, a trasferire la propria residenza nel Comune di Tratalias;" },
            { value: "5-Di essere l’unico componente del proprio nucleo famigliare a richiedere il contributo in oggetto;" },
            { value: "6-Di non essere beneficiario di altri contributi che prevedano il divieto di cumulabilità con il presente contributo;" },
            { value: "7-Di aver stipulato l’atto di acquisto dell’immobile successivamente alla data del 10 marzo 2022;" },
            { value: "8-Di aver avviato interventi di ristrutturazione sull’immobile successivamente alla data del 10 marzo 2022;" },
            { value: "9-Di essere a conoscenza che l’eventuale contributo è soggetto all’applicazione della ritenuta fiscale del 4% ai sensi dell’art. 28, 2° comma, del D.P.R. 29/09/1973 n. 600;" },
            { value: "10-Di aver preso visione e accettato tutte le clausole contenute nel Bando in oggetto;" },
            { value: "11-Di aver preso visione, all’art.13 del Bando, dell’informativa ai sensi del Reg. UE 2016/679 ed autorizzare il Trattamento dei Dati qui rilasciati per il procedimento;" },
            { value: "12-Di impegnarsi a fornire tempestivamente al Comune di Tratalias qualunque eventuale altro documento richiesto e necessario per completare la procedura di assegnazione del contributo per il tramite dei contatti prima dichiarati." },
        ],
        requirements: {
            residence: [
                { value: "1-Coloro i quali trasferiscono la propria residenza da un altro Comune non classificato come “Piccolo Comune” della Sardegna" },
            ],
            type: [
                { value: "1-Solo acquisto di immobile" },
                { value: "2-Solo ristrutturazione di immobile" },
                { value: "3-Intervento complesso di acquisto e ristrutturazione di immobile" },
                { value: "4-Intervento complesso di acquisto e ristrutturazione di immobile dichiarato inagibile" },
            ],
            family: [
                { value: "1-Nucleo familiare fino a 1 componente" },
                { value: "2-Nucleo familiare fino a 2 componenti" },
                { value: "3-Nucleo familiare fino a 4 componenti" },
                { value: "4-Nucleo familiare con 5 o più componenti" },
            ],
            applicant: [
                { value: "1-Giovani coppie costituenti un nucleo familiare composto da coniugi o da conviventi more uxorio (persona che ha una relazione affettiva e solidaristica stabile ed in comunione di vita con un'altra persona) che abbiano costituito nucleo da non più di cinque anni, in cui almeno uno dei due componenti non abbia superato i trentacinque anni e sia residente in un Comune non inserito nell’elenco dei piccoli comuni della Sardegna" },
                { value: "2-Nuclei familiari monogenitoriali" },
            ],
        },
        extra_fields: [
            {
                id: "costi",
                defaultValue: {}
            },
            {
                id: "requisiti",
                defaultValue: {}
            },
            {
                id: "marca_bollo",
                defaultValue: {}
            },
        ]
    }
}

const getValues = () => {
    const tenantCode = getTenantCode();
    return VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"]
}

const getExtraFields = (context) => {
    const tenantCode = getTenantCode();
    const values = VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"];
    if(context && values?.extra_fields){
        let fieldsObj = {}
        values?.extra_fields?.map(field => {
            fieldsObj[field?.id] = context?.[field?.id] || field?.defaultValue || null;
        })
        // console.log("fieldsObj", fieldsObj)
        return fieldsObj;
    }

    return {}
}

export const useBonus = (parentSaveFunc, config, parentSendFunc) => {
    /********************* hooks go down here *********************/
    const ctxBonus = useSelector(state => state.stepSlice.data.bonus);
    const ctxApplicant = useSelector(state => state.stepSlice.data.bonus.richiedente);
    const ctxIsee = useSelector(state => state.stepSlice.data.bonus.isee);
    const ctxCredits = useSelector(state => state.stepSlice.data.bonus.credito);
    const ctxCosts = useSelector(state => state.stepSlice.data.bonus.costi);
    const ctxFamily = useSelector(state => state.stepSlice.data.bonus.nucleo_familiare);
    const ctxDichiaro = useSelector(state => state.stepSlice.data.bonus.dichiaro);
    const ctxConsapevole = useSelector(state => state.stepSlice.data.bonus.consapevole);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);
    const isStopped = useSelector(state => state.stepSlice.data.stopped);
    const ctx = useSelector(state => state.stepSlice.data);
    const dispatcher = useDispatch();

    // console.log("ctx bonus", ctxBonus);
    /********************* state go down here *********************/
    const [data, setData] = useState({
        tipologia: ctxPrefs?.bonus?.tipologia,
        richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
        credito: ctxCredits || {
            intestatario: config?.credito?.intestatario || "",
            banca: config?.credito?.banca || "",
            filiale: config?.credito?.filiale || "",
            indirizzo: config?.credito?.indirizzo || "",
            codice_iban: config?.credito?.codice_iban || "",
            iban: config?.credito?.iban || "",
            base64_iban: config?.credito?.base64_iban || "",
        },
        isee: ctxIsee?.length > 0 && ctxIsee?.[0] !== null ? ctxIsee : [],
        nucleo_familiare: ctxFamily || [],
        dichiaro: ctxDichiaro || [],
        consapevole: ctxConsapevole || [],
        ...getExtraFields(ctxBonus)
    });
    const [values, setValues] = useState({
        bonuses: (config?.data?.bonus || config?.bonus || [{id: 1, title: DEFAULT_NOBONUS_VALUE}])?.map(bonus => ({id: bonus?.id || null, value: bonus.title || ""})) || [],
        ...getValues()
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    /********************* methods go down here *********************/
    ////////////////////// get functions //////////////////////
    const getBonusId = () => values?.bonuses?.find(bonus => bonus.value === data.tipologia)?.id || null

    const getBonusFields = () => {
        const bonusId = getBonusId();

        switch(bonusId){
            case "BA3":
                return ["richiedente", "isee", "nucleo_familiare", "dichiaro", "consapevole", "preferenze"]
            case "CAP":
                return ["richiedente", "isee", "credito", "costi", "requisiti", "marca_bollo", "dichiaro", "preferenze"]
            default:
                return ["richiedente", "isee", "credito", "preferenze"]
        }
    }

    ////////////////////// validate functions //////////////////////
    const isValidField = (id, isSendRequest) => {
        const bonusId = getBonusId();
        // console.log("isValidField", id, data);
        switch(id){
            case "richiedente":
            case "isee":
            case "nucleo_familiare":
                return data[id]?.length >= 0 ? true : false;
            case "credito":
                // const credit = data?.[id] || {}
                const credit = {
                    intestatario: "",
                    banca: "",
                    filiale: "",
                    indirizzo: "",
                    codice_iban: "",
                    iban: "",
                    base64_iban: "",
                    ...data?.[id]
                }
                let errByKey = {}
                // console.log("Object.keys(credit)", Object.keys(credit))
                for (let i of Object.keys(credit)) {
                    let err = false;
                    if (credit[i] === "" || !credit[i]) {
                      err = true;
                    }
                    else if (i === "codice_iban") {
                        const regex = RegExp("^IT\\d{2}[A-Za-z]\\d{10}[0-9A-Za-z]{12}$");
                        err = !regex.test(credit[i]);
                    }
                    if(err) errByKey[i] = err;
                }
                return Object.keys(errByKey)?.length > 0 ? errByKey : true;
            case "preferenze":
                return data?.tipologia ? true : false
            case "dichiaro":
                switch(bonusId){
                    case "CAP": 
                        return data?.dichiaro?.length === 12
                    default:
                        return data?.dichiaro?.length === 2
                }
            case "consapevole":
                return data?.consapevole?.length === 2
            case "costi":
                return (data?.costi?.importo_prima_casa || data?.costi?.importo_interventi) ? true : false
            case "requisiti":
                return (
                    data?.requisiti?.tipo_intervento &&
                    data?.requisiti?.nucleo_familiare &&
                    data?.requisiti?.richiedente) || !isSendRequest ? true : false
            case "marca_bollo":
                return (
                    data?.marca_bollo?.numero?.length === 14 && 
                    data?.marca_bollo?.data) ? true : false
            default:
                return data[id] ? true : false
        }
    }

    const checkFields = (fields) => {
        // console.log("checkFields", fields)
        if(fields?.length > 0){
            let isValid = true;
            for(let key in fields){
                isValid = typeof isValidField(fields[key]) !== "object" && isValidField(fields[key]) === true;
                // console.log("field", data, fields[key], isValid)
                if(!isValid) break;
            }
            return isValid;
        }

        return false;
    }

    const isValidData = (isSendRequest) => {
        const bonusId = getBonusId();
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(bonusId){
            // case "BA3":
            //     break;
            case "CAP":
                if(!isSendRequest) requiredFields = ["richiedente", "preferenze"]
                else requiredFields = getBonusFields();
                break;
            default:
                if(isSendRequest) requiredFields = getBonusFields()
                else requiredFields = []
        }

        if(requiredFields?.length > 0) return checkFields(requiredFields)        

        return true;
    }

    const checkErrors = () => {
        let newError = {...error}

        const fields = getBonusFields() || []
        fields.map(field => {
            let isValid = isValidField(field, true)
            newError[field] = typeof isValid === "object" ? isValid : !isValid
        })
        
        setError(newError);
    }

    const isValidStep = (stepId) => {
        // console.log("data", data);
        const bonusId = getBonusId();
        switch(stepId){
            case "general":
                return (error?.preferenze || data?.tipologia === DEFAULT_NOBONUS_VALUE) ? false : true
            case "specific":
            case "summary":
                switch(bonusId) {
                    case "CAP": 
                        return (
                            error?.richiedente 
                            || (typeof error.credito === "object" ? Object.keys(error.credito)?.length > 0 : error.credito)
                            || error.dichiaro
                            || error.costi
                            || error.marca_bollo
                            || error.requisiti
                        ) ? false : true
                    default:
                        return (error?.richiedente 
                            || (typeof error.credito === "object" ? Object.keys(error.credito)?.length > 0 : error.credito) 
                            || error.isee 
                            || error.dichiaro || error.consapevole 
                            || isStopped) ? false : true
                }
            default:
                return false;
        }
    }
    ////////////////////// UPDATE //////////////////////
    const parseField = (id, newData) => {
        switch(id){
            case "richiedente":
                return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
            case "nucleo_familiare":
                // console.log("nucleo_familiare", data, newData)
                if(!newData?.removing && !newData?.isEdit){
                    return [
                        ...(data[id] || []),
                        {...newData?.data?.[0]}
                    ]
                }
                else if(newData?.removing && newData?.index !== undefined){
                    let newArrayData = []
                    data[id]?.map((member, idx) => {
                        if(idx !== newData?.index)
                            newArrayData.push(member)
                    })
                    // console.log("newArrayData", newArrayData)
                    return newArrayData
                }
                else if(newData?.isEdit){
                    let newArrayData = [...(data[id] || [])]
                    if(newArrayData[newData?.index])
                        newArrayData[newData?.index] = newData?.data?.[0]

                    return newArrayData
                }
                // return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
                break;  
            default:
                return newData?.data || null
        }
    }

    const update = (newData) => {
        // console.log("useBonus update", newData, typeof newData === "object", Array.isArray(newData));
        if(typeof newData === "object" && !Array.isArray(newData)){
            const id = newData?.id?.toLowerCase();
            const internalId = newData?.internalId || null;
            if(id){
                if(internalId){
                    setData(oldData => ({
                        ...oldData,
                        [id]: {
                            ...(data?.[id] || {}),
                            [internalId]: newData?.data || null
                        }
                    }))
                }
                else{
                    setData(oldData => ({
                        ...oldData,
                        [id]: parseField(id, newData)
                    }))
                }
            }
        }
        else if (Array.isArray(newData)){
            let updatedData = {...data};
            newData.map(newDataItem => {
                const id = newDataItem?.id?.toLowerCase();
                const internalId = newDataItem?.internalId || null;
                if(id){
                    if(internalId){
                        updatedData = {
                            ...updatedData,
                            [id]: {
                                ...(updatedData?.[id] || {}),
                                [internalId]: newDataItem?.data || null
                            }
                        }
                    }
                    else{
                        updatedData = {
                            ...updatedData,
                            [id]: parseField(id, newDataItem)
                        }
                    }
                }
            })
            // console.log("updatedData", updatedData)
            setData(updatedData);
        }
    }

    const updateField = (e, fieldId, isInternal) => {
        // console.log("e", e?.target?.id, e?.target?.value);
        const id =(e?.id || e?.target?.id)?.toLowerCase();
        const value = e?.data || e?.target?.value;
        if((fieldId && id && isInternal) || (fieldId && !isInternal)){
            update({
                id: isInternal ? fieldId : id,
                internalId: isInternal ? id : null,
                data: value
            })
        }
    }

    ////////////////////// SAVE //////////////////////
    const savePrefs = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "bonus", data: {
            tipologia: data?.tipologia
        } }));
    }

    const save = async (isSendRequest) => {
        const fields = getBonusFields() || []
        let saveData = {}
        fields.map(field => {
            let isValid = typeof isValidField(field, isSendRequest) !== "object" && isValidField(field) === true;
            // console.log("is valid data", field, isValid)
            if(data[field] && isValid) saveData[field] = data[field];
        })

        if(data?.tipologia){
            saveData.preferenze = {
                bonus: {tipologia: data.tipologia},
                // dichiaro: data?.dichiaro || [],
                // consapevole: data?.consapevole || [],
            }
        }

        // console.log("fields", saveData)
        // console.log("save data", saveData)
        let isSend = typeof isSendRequest === "boolean" ? isSendRequest : false;
        if(isValidData(isSend) && ((parentSaveFunc && !isSend) || (parentSendFunc && isSend))){
            dispatcher(stepSlice.actions.dynamicSave({ id: "Bonus", data: {...saveData} }));
            savePrefs();
            setLoading(true);
            if(isSend){
                await parentSendFunc(saveData);
            }
            else{
                await parentSaveFunc(saveData);
            }
            setLoading(false);
        }
    }

    const clear = () => {
        setData({})
    }

    /********************* useEffects go down here *********************/
    useEffect(() => {
        // console.log("config", config);
        if((config?.data?.bonus || config?.bonus) && values?.bonuses?.length === 0){
            setValues({
                bonuses: (config?.data?.bonus || config?.bonus || [])?.map(bonus => ({id: bonus?.id || null, value: bonus.title || ""})) || []
            })
        }
    }, [config])

    useEffect(() => {
        // console.log("useBonus", data, values);
        dispatcher(stepSlice.actions.dynamicSave({ id: "Bonus", data: data || {} }));
        checkErrors();
    }, [data]);

    useEffect(() => {
        // console.log("useBonus error", error, data);
    }, [error])

    useEffect(() => {
        checkErrors();
    }, [])

    return {
        data,        // current data of the service request
        values,      // values to populate fields such as dropdowns
        loading,     // true = is saving request 
        error,       // error flag by data[id] eg. data["richiedente"] is invalid -> error.richiedente = true
        save,        // function to save the current service request
        savePrefs,   // function to save prefs into the store
        update,      // function to update the data object
        updateField,
        isValidStep, // function to check <stepId> data -> useful for nextButton disable status
        getBonusId,  // function to get the current bonusId
    }
}