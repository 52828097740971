import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import { useTowZone } from "./towZone";

function Summary(props) {
  const {data, values, loading, error, isValidStep, getPrefs, update, updateField, updatePrefs, save, savePrefs} = useTowZone(props.save, null, props.send);
  
  const onNext = async () => {
    save(true);
  };
  
  const applicant = data?.richiedente?.[0];

  return (

    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            <SummaryCard
              type="richiedente"
              cardTitle={`${applicant?.anagrafica?.nome} ${applicant?.anagrafica?.cognome}`}
              mod={true}
              update={update}
              id={"Richiedente"}
              taxID={applicant?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={applicant} >
              <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
              <FormBoxField title="Email" value={applicant?.contatti.email} />
              <FormBoxField title="Carta di identità " value={applicant?.documenti.carta_di_identita} />
            </SummaryCard>

            <SummaryCard
              type="Preferenze"
              cardTitle="Preferenze di servizio"
              mod={true}
              service="PPC"
              // registryData={ctx}
              registryData={{
                towZone: data
            }}
              choiceData={[
                {
                  "options": values?.types || [],
                  "title": [
                    "PASSO CARRABILE",
                    "TIPOLOGIA"
                  ],
                  "type": "Select"
                },
                {
                  "options": values?.request_types || [],
                  "title": [
                    "PASSO CARRABILE",
                    "CHIEDE"
                  ],
                  "type": "Select"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Motivo"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "MOTIVO"
                  ],
                  "child_title": [],
                  "type": "MultiText"
                },

                {
                  "options": [
                    {
                      "id": "numero",
                      "title": "Numero provvedimento",
                      "useId": true
                    },
                    {
                      "id": "data",
                      "title": "Data provvedimento",
                      "type": "date",
                      "useId": true
                    }
                  ],
                  "title": [
                    "PASSOCARRABILE",
                    "PROVVEDIMENTO"
                  ],
                  "child_title": [],
                  "type": "MultiText",
                  "id": "provvedimento"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Indirizzo"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "LUOGO"
                  ],
                  "child_title": [
                    "Indirizzo",
                  ],
                  "type": "MultiText"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Lunghezza"
                    },
                    {
                      "id": 1,
                      "title": "Profondità"
                    },
                    {
                      "id": 1,
                      "title": "Superficie"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "DIMENSIONI"
                  ],
                  "child_title": [
                    "Lunghezza",
                    "Profondita",
                    "Superficie"
                  ],
                  "type": "MultiText"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Foglio"
                    },
                    {
                      "id": 1,
                      "title": "Particella"
                    },
                    {
                      "id": 1,
                      "title": "Subalterno"
                    },
                    {
                      "id": 1,
                      "title": "Categoria"
                    }
                  ],
                  "title": [
                    "PASSO CARRABILE",
                    "Immobile"
                  ],
                  "child_title": [
                    "Foglio",
                    "Particella",
                    "Subalterno",
                    "Categoria",
                  ],
                  "type": "MultiText",
                  "id": "immobile"
                },
                {
                  "options": [
                      {
                          "id": "numero",
                          "title": "Numero marca da bollo",
                          "regex": "^[0-9]{14}$",
                          "max": "14",
                          "useId": true
                      },
                      {
                          "id": "data",
                          "title": "Data marca da bollo",
                          "useId": true,
                          "type": "date"
                      }
                  ],
                  "title": [
                      "ACCESSI",
                      "MARCA BOLLO"
                  ],
                  "child_title": [],
                  "type": "MultiText",
                  "id": "marca_bollo"
                }
              ]
              }
              update={updatePrefs}>
              <FormBoxField title="Tipologia" value={data?.tipologia || "Non compilato"} />
              <FormBoxField title="Motivo" value={data?.motivo || "Non compilato"} />
              <FormBoxField title="Chiede" value={data?.chiede || "Non compilato"} />
              <FormBoxField title="Numero" value={data?.provvedimento.numero || "Non compilato"} />
              <FormBoxField title="Data" value={data?.provvedimento.data || "Non compilato"} />
              <FormBoxField title="Indirizzo" value={data?.luogo.indirizzo || "Non compilato"} />
              <FormBoxField title="Lunghezza" value={data?.dimensioni.lunghezza || "Non compilato"} />
              <FormBoxField title="Profondità" value={data?.dimensioni.profondita || "Non compilato"} />
              <FormBoxField title="Superficie" value={data?.dimensioni.superficie || "Non compilato"} />
              <FormBoxField title="Foglio" value={data?.immobile.foglio || "Non compilato"} />
              <FormBoxField title="Particella" value={data?.immobile.particella || "Non compilato"} />
              <FormBoxField title="Subalterno" value={data?.immobile.subalterno || "Non compilato"} />
              <FormBoxField title="Categoria" value={data?.immobile.categoria || "Non compilato"} />
              <FormBoxField title="Numero marca da bollo" value={data?.marca_bollo?.numero || "Non compilato"} />
              <FormBoxField title="Data marca da bollo" value={data?.marca_bollo?.data || "Non compilato"} />
            </SummaryCard>

            <NextBtnForm
              send={onNext}
              back={props.back}
              saveRequest={save}
              last={true}
              disabled={!isValidStep("summary")}
              noSave={false}
            />
            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }

          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;