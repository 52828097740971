import React, { useEffect } from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";


function AccessUiForm(props) {
  const isBeneficiary = props.id === "Beneficiario";
  const isDelegate = props.id === "Delegato";
  const isEdit = props.isEdit;
  let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {};

  const prefData = props.prefData;
  // console.log("AccessUiForm", props, isDelegate, isEdit);

  const initField = (field, internalId, value) => {
    if(!props?.defaultValue?.[field]?.[internalId] && field && internalId && value)
      onDataChange2(null, props.setCustomerData, internalId, (isDelegate && !isEdit) ? "" : value)
  }

  const init = () => {
    props.setCustomerData(prev => ({
      ...prev,
      anagrafica: {
        ...(prev?.anagrafica || {}),
        nome: user?.name,
        cognome: user?.familyName,
        codice_fiscale: user?.fiscalNumber,
        data_di_nascita: user?.dateOfBirth,
        luogo_di_nascita: user?.placeOfBirth,
      },
      indirizzi: {
        ...(prev?.indirizzi || {}),
        residenza: user?.domicileStreetAddress,
        domicilio: user?.domicileMunicipality,
      },
      contatti: {
        ...(prev?.contatti || {}),
        telefono: user?.mobilePhone,
        email: user?.email,
      },
    }))
  }

  useEffect(() => {
    if(props.setCustomerData && !props?.defaultValue?.anagrafica?.nome){
      init(); 
    }
    // initField("anagrafica", "nome", user?.name);
    // initField("anagrafica", "cognome", user?.familyName);
    // initField("anagrafica", "codice_fiscale", user?.fiscalNumber);
    // initField("anagrafica", "data_di_nascita", user?.dateOfBirth);
    // initField("anagrafica", "luogo_di_nascita", user?.placeOfBirth);
    // initField("indirizzi", "residenza", user?.domicileStreetAddress);
    // initField("indirizzi", "domicilio", user?.domicileMunicipality);
    // initField("contatti", "telefono", user?.mobilePhone);
    // initField("contatti", "email", user?.email);
  }, [])

  // console.log("access ui form", props);
  return (
    <PopupBox close={props.close} save={props.save} title={props?.id}>
      {
        props.error &&
        <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
      }

      <PopupTextField
        disabled={isDelegate ? false : true}
        defaultValue={(isDelegate && !isEdit) ? "" : props?.defaultValue?.anagrafica?.nome || user?.name}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="nome"
        fieldTitle="Nome*"
        description={isBeneficiary ? "" : (isDelegate ? "Inserire l’informazione per proseguire con la richiesta" : " ")}
        error={props.error}
      />
      <PopupTextField
        defaultValue={(isDelegate && !isEdit) ? "" : props?.defaultValue?.anagrafica?.cognome || user?.familyName}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="cognome"
        fieldTitle="Cognome"
        disabled={isDelegate ? false : true}
        description={isBeneficiary ? "" : (isDelegate ? "Inserire l’informazione per proseguire con la richiesta" : " ")}
        error={props.error}
      />
      <PopupTextField
        description={isBeneficiary ? "" : (isDelegate ? "Inserire l’informazione per proseguire con la richiesta" : " ")}
        defaultValue={(isDelegate && !isEdit) ? "" : props?.defaultValue?.anagrafica?.codice_fiscale || user?.fiscalNumber}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="codice_fiscale"
        fieldTitle="Codice Fiscale*"
        disabled={isDelegate ? false : true}
        regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="date"
        id="data_di_nascita"
        fieldTitle="Data di nascita*"
        defaultValue={(isDelegate && !isEdit) ? "" : (props?.defaultValue?.anagrafica?.data_di_nascita || user.dateOfBirth)}
        error={props.error}
        disabled={isDelegate ? false : true}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="luogo_di_nascita"
        fieldTitle="Luogo di nascita*"
        defaultValue={(isDelegate && !isEdit) ? "" : (props?.defaultValue?.anagrafica?.luogo_di_nascita || user.placeOfBirth)}
        error={props.error}
        disabled={isDelegate ? false : true}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="residenza"
        fieldTitle="Indirizzo residenza*"
        defaultValue={(isDelegate && !isEdit) ? "" : props?.defaultValue?.indirizzi?.residenza || user?.domicileStreetAddress}
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="domicilio"
        fieldTitle="Comune residenza*"
        defaultValue={(isDelegate && !isEdit) ? "" : props?.defaultValue?.indirizzi?.domicilio || user?.domicileMunicipality}
        error={props.error}
      />
      <>
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="text"
          id="telefono"
          fieldTitle="Telefono*"
          defaultValue={(isDelegate && !isEdit) ? "" : props?.defaultValue?.contatti?.telefono || user?.mobilePhone}
          regex="[0-9]{8}"
          error={props.error}
        />
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="email"
          id="email"
          fieldTitle="Email*"
          defaultValue={(isDelegate && !isEdit) ? "" : props?.defaultValue?.contatti?.email || user?.email}
          regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
          error={props.error}
        />
      </>

      <PopupTextField
        onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
        defaultValue={(isDelegate && !isEdit) ? "" : (isBeneficiary ? props.defaultValue?.documenti?.certificato_disabilita || prefData?.documenti?.certificato_disabilita || "" : props.defaultValue?.documenti?.carta_di_identita || prefData?.documenti?.carta_di_identita || "")}
        fieldType="file"
        id={isBeneficiary ? "certificato_disabilita" : "carta_di_identita"}
        fieldTitle={isBeneficiary ? "Certificato disabilità" : "Carta di identità*"}
        error={!isBeneficiary ? props.error : false}
      />

      {props?.defaultValue?.type?.type === "Delegato" &&
        <PopupTextField
          onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
          defaultValue={props.defaultValue?.documenti?.delega || prefData?.documenti?.delega || ""}
          fieldType="file"
          id={"delega"}
          fieldTitle={"Delega*"}
          error={props.error}
        />
      }


    </PopupBox>





  )
}

export default AccessUiForm;