import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";
import PopupSelectField from "../ui-el/PopupSelectField";


function ParkingUIForm(props) {
  const isAcustic = props.id ? ("" + props.id)?.toLocaleLowerCase() === "disabile" : false;
  const prefData = props.prefData;
  let user = localStorage.getItem("user") ? JSON.parse(JSON.parse(localStorage.getItem("user"))?.data) : {};

  const onChoice = (e) => {
    props.setCustomerData(prev => {
      const obj = structuredClone(prev);
      obj.anagrafica.tipo_richiedente = e.target.options[e.target.selectedIndex].text;
      prev = obj;
      return { ...prev }
    })
  }

  // console.log("parkign", props);
  // console.log(props?.defaultValue?.anagrafica?.nome, prefData?.anagrafica?.nome);

  return (
    <PopupBox close={props.close} save={props.save} title={props?.title || props?.id}>
      {
        props.error &&
        <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
      }
      {
        !isAcustic &&
        <PopupSelectField
          defaultValue={props?.defaultValue?.anagrafica?.tipo_richiedente || prefData?.anagrafica?.tipo_richiedente}
          onChange={onChoice}
          id="tipo_richiedente"
          fieldTitle={"Tipo richiedente"}
          options={["Richiedente", "Esercente la potestà", "Tutore legale", "Curatore"]}
          description="Seleziona il tipo di tichiedente" />
        }

      <PopupTextField
        disabled={!isAcustic}
        defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.anagrafica?.nome || prefData?.anagrafica?.nome : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="nome"
        fieldTitle="Nome*"
        description={isAcustic ? "" : " "}
        error={props.error}
      />
      <PopupTextField
        defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.anagrafica?.cognome || prefData?.anagrafica?.cognome : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="cognome"
        fieldTitle="Cognome*"
        disabled={isAcustic ? false : true}
        description={isAcustic ? "" : " "}
        error={props.error}
      />
      <PopupTextField
        description={isAcustic ? "" : " "}
        defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.anagrafica?.codice_fiscale || prefData?.anagrafica?.codice_fiscale : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="codice_fiscale"
        fieldTitle="Codice Fiscale*"
        disabled={isAcustic ? false : true}
        regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="date"
        id="data_di_nascita"
        fieldTitle="Data di nascita*"
        defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.anagrafica?.data_di_nascita || prefData?.anagrafica?.data_di_nascita || user.dateOfBirth : ""}
        error={props.error}
        disabled={isAcustic ? false : true}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="luogo_di_nascita"
        fieldTitle="Luogo di nascita*"
        defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.anagrafica?.luogo_di_nascita || prefData?.anagrafica?.luogo_di_nascita || user.placeOfBirth : ""}
        error={props.error}
        disabled={isAcustic ? false : true}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="residenza"
        fieldTitle="Indirizzo residenza*"
        defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.indirizzi?.residenza || prefData?.indirizzi?.residenza : ""}
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="domicilio"
        fieldTitle="Comune residenza*"
        defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.indirizzi?.domicilio || prefData?.indirizzi?.domicilio : ""}
        error={props.error}
      />
      <>
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="text"
          id="telefono"
          fieldTitle="Telefono*"
          defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.contatti?.telefono || prefData?.contatti?.telefono : ""}
          regex="[0-9]{8}"
          error={props.error}
        />
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="email"
          id="email"
          fieldTitle="Email*"
          defaultValue={(!isAcustic || props?.isEdit) ? props?.defaultValue?.contatti?.email || prefData?.contatti?.email : ""}
          regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
          error={props.error}
        />
      </>


      <PopupTextField onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
        fieldType="file"
        id={"carta_di_identita"}
        defaultValue={(!isAcustic || props?.isEdit) ? props.defaultValue?.documenti?.carta_di_identita || prefData?.documenti?.carta_di_identita || "" : props.defaultValue?.documenti?.carta_di_identita || ""}
        fieldTitle={"Carta di identità*"}
        description={""}
        error={props.error}
      />

      {
        isAcustic ?
          <>
            <PopupTextField
              onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
              defaultValue={(props?.isEdit) ? props.defaultValue?.documenti?.fototessera || prefData?.documenti?.fototessera || "" : props.defaultValue?.documenti?.fototessera || ""}
              fieldType="file"
              id="fototessera"
              fieldTitle={"Fototessera*"}
              description=""
              error={props.error} />
            <PopupTextField
              onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
              defaultValue={(props?.isEdit) ? props.defaultValue?.documenti?.certificato_ufficio || prefData?.documenti?.certificato_ufficio || "" : props.defaultValue?.documenti?.certificato_ufficio || ""}
              fieldType="file"
              id="certificato_ufficio"
              fieldTitle={"Certificato ufficio medico legale ASL"}
              description=" "
              error={false}
            />
            <PopupTextField
              onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
              defaultValue={(props?.isEdit) ? props.defaultValue?.documenti?.certificato_medico || prefData?.documenti?.certificato_medico || "" : props.defaultValue?.documenti?.certificato_medico || ""}
              fieldType="file"
              id="certificato_medico"
              fieldTitle="Certificato del medico curante - modello C4"
              description=" "
              error={false}
            />
            <PopupTextField
              onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
              defaultValue={(props?.isEdit) ? props.defaultValue?.documenti?.denuncia || prefData?.documenti?.denuncia || "" : props.defaultValue?.documenti?.denuncia || ""}
              fieldType="file"
              id="denuncia"
              fieldTitle="Denuncia di smarrimento o furto (in caso di duplicato)"
              description=" "
              error={false}
            />
          </> :
          <PopupTextField onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
            fieldType="file"
            id={"attestazione_esercente"}
            defaultValue={(!isAcustic || props?.isEdit) ? props.defaultValue?.documenti?.attestazione_esercente || prefData?.documenti?.attestazione_esercente || "" : props.defaultValue?.documenti?.attestazione_esercente || ""}
            fieldTitle={"Attestazione la potestà, tutore legale o curatore"}
            description={" "}
            error={false}
          />
      }


    </PopupBox>
  )
}

export default ParkingUIForm;