import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { onDataChange2, onDirectDataChange } from "../../../util/Dynamics";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";
import { useTowZone } from "./towZone";

function Specific(props) {
  const {data, values, loading, error, isValidStep, update, updateField, save, savePrefs} = useTowZone(props.save);

  const onNext = () => {
    savePrefs();
    props.next();
  }

  // console.log("error", error);
  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Tipologia", id: "#report-author" },
          { element: "Motivo", id: "#report-author" },
          { element: "Chiede", id: "#report-author" },
          { element: "Provvedimento", id: "#report-author" },
          { element: "Luogo", id: "#report-author" },
          { element: "Dimensioni", id: "#report-author" },
          { element: "Immobile", id: "#report-author" },
          { element: "Marca da bollo", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <ErrorAlert errors={[{ name: "Motivo", id: "#isee-info" }]} err={error.motivo} />
            <ErrorAlert errors={[{ name: "Chiede", id: "#isee-info" }]} err={error.chiede} />
            <ErrorAlert errors={[{ name: "Luogo", id: "#isee-info" }]} err={error.luogo} />
            <ErrorAlert errors={[{ name: "Dimensioni", id: "#isee-info" }]} err={error.dimensioni} />
            <ErrorAlert errors={[{ name: "Immobile", id: "#isee-info" }]} err={error.immobile} />
            <ErrorAlert errors={[{ name: "Marca da bollo", id: "#isee-info" }]} err={error.marca_bollo} />

            <Loader loading={loading} fixedPosition/>

            <SelectionFormBox
              showTitle={`Tipologia*`}
              title={"tipologia"}
              description={" "}
              choices={values?.types || []}
              defaultValue={data?.tipologia}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

            <SummaryCard
              type="Motivo"
              description=" "
            >
              <PopupTextField
                defaultValue={data?.motivo}
                fieldType="text"
                id="motivo"
                fieldTitle="Motivo"
                description="Aggiungi un motivo alla tua richiesta"
                onChange={(e) => { updateField(e, "motivo", false)}}
              />
            </SummaryCard>

            <SelectionFormBox
              showTitle={`Chiede*`}
              title={"chiede"}
              description={" "}
              choices={values?.request_types || []}
              defaultValue={data?.chiede}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

            <SummaryCard
              type="Provvedimento"
              description="Da indicare in caso di richieste per passo carrabile già autorizzato"
            >
              <PopupTextField
                defaultValue={data?.provvedimento?.numero}
                fieldType="text"
                id="numero"
                fieldTitle="Numero"
                description="Aggiungi un numero alla tua richiesta"
                onChange={(e) => { updateField(e, "provvedimento", true)}}
              />
              <PopupTextField
                defaultValue={data?.provvedimento?.data}
                fieldType="date"
                id="data"
                fieldTitle="Data"
                description="Aggiungi una numero alla tua richiesta"
                onChange={(e) => { updateField(e, "provvedimento", true)}}
              />
            </SummaryCard>

            <SummaryCard
              type="Luogo"
              description=" "
            >
              <PopupTextField
                defaultValue={data?.luogo?.indirizzo}
                fieldType="text"
                id="indirizzo"
                fieldTitle="Indirizzo"
                description="Aggiungi un indirizzo alla tua richiesta"
                onChange={(e) => { updateField(e, "luogo", true)}}
              />
            </SummaryCard>

            <SummaryCard
              type="Dimensioni"
              description=""
            >
              <PopupTextField
                defaultValue={data?.dimensioni?.lunghezza}
                fieldType="text"
                id="lunghezza"
                fieldTitle="Lunghezza"
                description="Aggiungi una lunghezza alla tua richiesta"
                onChange={(e) => { updateField(e, "dimensioni", true)}}
              />
              <PopupTextField
                defaultValue={data?.dimensioni?.profondita}
                fieldType="text"
                id="profondita"
                fieldTitle="Profondità"
                description="Aggiungi la profondità alla tua richiesta"
                onChange={(e) => { updateField(e, "dimensioni", true)}}
              />
              <PopupTextField
                defaultValue={data?.dimensioni?.superficie}
                fieldType="text"
                id="superficie"
                fieldTitle="Superficie"
                description="Aggiungi la superficie alla tua richiesta"
                onChange={(e) => { updateField(e, "dimensioni", true)}}
              />
            </SummaryCard>


            <SummaryCard
              type="Immobile"
              description=""
            >
              <PopupTextField
                defaultValue={data?.immobile?.foglio}
                fieldType="text"
                id="foglio"
                fieldTitle="Foglio"
                description="Aggiungi un foglio alla tua richiesta"
                onChange={(e) => { updateField(e, "immobile", true)}}
              />
              <PopupTextField
                defaultValue={data?.immobile?.particella}
                fieldType="text"
                id="particella"
                fieldTitle="Particella"
                description="Aggiungi la particella alla tua richiesta"
                onChange={(e) => { updateField(e, "immobile", true)}}
              />
              <PopupTextField
                defaultValue={data?.immobile?.subalterno}
                fieldType="text"
                id="subalterno"
                fieldTitle="Subalterno"
                description="Aggiungi un subalterno alla tua richiesta"
                onChange={(e) => { updateField(e, "immobile", true)}}
              />
              <PopupTextField
                defaultValue={data?.immobile?.categoria}
                fieldType="text"
                id="categoria"
                fieldTitle="Categoria"
                description="Aggiungi una categoria alla tua richiesta"
                onChange={(e) => { updateField(e, "immobile", true)}}
              />
            </SummaryCard>

            <SummaryCard type="Marca da bollo*">
              <PopupTextField
                  defaultValue={data?.marca_bollo?.numero}
                  onChange={(e) => { updateField(e, "marca_bollo", true, "numero")}}
                  id="numero_marca"
                  fieldType="text"
                  fieldTitle="Numero"
                  description=""
                  regex="^[0-9]{14}$"
                  max="14"
                  min="14" />
              <PopupTextField
                  defaultValue={data?.marca_bollo?.data}
                  onChange={(e) => { updateField(e, "marca_bollo", true)}}
                  id="data"
                  fieldType="date"
                  fieldTitle="Data"
                  description=""
                  min={new Date().toISOString().split('T')[0]}
              />
            </SummaryCard>
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          saveRequest={save}
          disabled={!isValidStep("specific")} />
          
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}

export default Specific;