import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import { useTowZone } from "./towZone";

function General(props) {
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  const {data, values, loading, error, isValidStep, update, updateField, save, savePrefs} = useTowZone(props.save);

  const onNext = () => {
    props.next();
  }


  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#report-author" },
        ]} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error?.richiedente || isStopped} />
            }
            <Loader loading={loading} fixedPosition/>

            <CustomerFormBox id="richiedente"
              cards={data?.richiedente?.length > 0 ? [data?.richiedente] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente"
              service={props.info.code}
              update={update}
              prefData={props.info.data?.richiedente}
            />
          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          saveRequest={save}
          disabled={!isValidStep("general")} />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default General;