import React, { useEffect, useState } from "react";
import { titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";
import { formatCheckboxValue } from "../util";

function CheckedFormBox(props) {

  const [testData, setTestData] = useState(props.defaultValue ?? []);

  const onChange = (e) => {
    if (props.type === "checkbox") {
      const key = e.target.value.split("-")[0];
      // console.log("key", key);
      if (e.target.checked) {
        for (let i of testData) {
          if (i.includes(`${key}-`))
            return;
        }

        setTestData(prev => {
          const obj = structuredClone(prev);
          obj.push(e.target.value);

          return [...obj];
        });
      } else {
        setTestData(prev => {
          const id = prev.findIndex((itm) => {
            return itm.includes(`${key}-`);
          });

          const obj = structuredClone(prev);
          obj.splice(id, 1);

          return [...obj];
        });
      }
    } else {
      setTestData(prev => {
        return [e.target.value];
      });
    }
  }

  useEffect(() => {
    // console.log("///// CheckedFormBox", props.defaultValue, testData, props?.id || titleNormalizer(props.title[1].normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
    props.update({
      id: props?.id || titleNormalizer(props.title[1].normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
      data: testData
    });
  }, [testData])

  useEffect(() =>  {
    // console.log("check ", testData,)
    if(testData !== props.defaultValue && props.defaultValue)
      setTestData(props.defaultValue);
  }, [props.defaultValue])

  const renderer = () => {
    return props.choices.map((choice, idx) => {
      const id = parseInt(uniqueIdBuilder() + idx + 1);
      let normalizedTitle = titleNormalizer(props.title[1]);
      // console.log("choice.value?.indexOf("-")", choice.value?.indexOf("-"))
      if (testData?.includes(choice.value)) {
        return (
          <div
            className="radio-body border-bottom border-light"
            key={uniqueIdBuilder()}>
            <p style={{ display: "none" }}>{props.title[1]}</p>
            <input
              name={`${normalizedTitle}`}
              type={props.type}
              id={`${normalizedTitle}-${id}`}
              onChange={onChange}
              value={choice?.value}
              checked />
            <label htmlFor={`${normalizedTitle}-${id}`}>{formatCheckboxValue(choice?.value)}</label>
          </div>
        )
      } else {
        return (
          <div
            className="radio-body border-bottom border-light"
            key={uniqueIdBuilder()}>
            <p style={{ display: "none" }}>{props.title[1]}</p>
            <input
              name={`${normalizedTitle}`}
              type={props.type}
              id={`${normalizedTitle}-${id}`}
              value={choice?.value}
              onChange={onChange}
            />
            <label htmlFor={`${normalizedTitle}-${id}`}>{formatCheckboxValue(choice?.value)}</label>
          </div>
        )
      }
    })
  }

  return (
    <section className="it-page-section" id="rate-choice">
      <div className="cmp-card mb-40">
        <div className="card has-bkg-grey shadow-sm p-big">
          <div className="card-header border-0 p-0 mb-lg-30 m-0">
            <div className="d-flex">
              <h2 className="title-xlarge mb-1">
                {
                  titleExtractor(props.title[1])
                }
                *
              </h2>
            </div>
            <p className="subtitle-small mb-0 mb-4">{props.description}</p>
          </div>

          <div className="card-body p-0">
            <div className="cmp-card-radio">
              <div className="card card-teaser">
                <div className="card-body">
                  <div className="form-check m-0">
                    {
                      renderer()
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default CheckedFormBox;











