import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import { getTenantCode } from "../../../util";

const VALUES_BY_TENANT = {
    "default": {
        declarations: [
            { value: "1-di conoscere le sanzioni amministrative e penali previste dagli artt. 75 e 76 del D.P.R. 445/2000, “Testo unico delle disposizioni legislative e regolamentari in materia di documentazione amministrativa" },
            { value: "2-di voler ricevere quanto richiesto personalmente presso l’ufficio competente del Comune, oppure al proprio indirizzo di posta elettronica, oppure che gli atti siano inviati all’indirizzo di spedizione indicato mediante raccomandata con avviso di ricevimento con spesa a proprio carico. Il rilascio di dati o documenti in formato elettronico o cartaceo è gratuito, salvo il rimborso del costo effettivamente sostenuto e documentato dall’amministrazione per la riproduzione su supporti materiali" }
        ],
        request_types : [
            {id: '1', value: 'Apertura di un nuovo passo carrabile'},
            {id: '2', value: 'Regolarizzazione di un passo carrabile esistente'},
            {id: '3', value: 'Chiusura di un passo carragile già autorizzato'},
            {id: '4', value: 'Voltura della titolarità di passo carrabile già autorizzato'},
            {id: '5', value: 'Modifica delle titolarità di passo carrabile già autorizzato'},
            {id: '6', value: 'Modifica della metratura di passo carrabile già autorizzato'},
            {id: '7', value: 'Modifica della situazione contributiva del soggetto titolare della concessione'},
            {id: '8', value: 'Altro'},
        ],
        types: [
            {id: '1', value: 'Proprietario'},
            {id: '2', value: 'Locatorio'},
            {id: '3', value: 'Altro'},
        ]
    }
}

const getValues = () => {
    const tenantCode = getTenantCode();
    return VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"]
}

const getExtraFields = (context) => {
    const tenantCode = getTenantCode();
    const values = VALUES_BY_TENANT[tenantCode] || VALUES_BY_TENANT["default"];
    if(context && values?.extra_fields){
        let fieldsObj = {}
        values?.extra_fields?.map(field => {
            fieldsObj[field?.id] = context?.[field?.id] || field?.defaultValue || null;
        })
        // console.log("fieldsObj", fieldsObj)
        return fieldsObj;
    }

    return {}
}

export const useTowZone = (parentSaveFunc, config, parentSendFunc) => {
    /********************* hooks go down here *********************/
    const ctxApplicant = useSelector(state => state.stepSlice.data.towZone?.richiedente);
    const ctxSpecific = useSelector(state => state.stepSlice.data.preferenze?.passo_carrabile);
    const ctxPrefs = useSelector(state => state.stepSlice.data.preferenze);
    const isStopped = useSelector(state => state.stepSlice.data.stopped);
    const ctx = useSelector(state => state.stepSlice.data?.towZone);
    const dispatcher = useDispatch();
    // console.log("use tow zone", ctx);
    /********************* state go down here *********************/
    const [data, setData] = useState({
        richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
        tipologia: ctxSpecific?.tipologia || "",
        chiede: ctxSpecific?.chiede || "",
        motivo: ctxSpecific?.motivo || "",
        provvedimento: ctxSpecific?.provvedimento || {},
        luogo: ctxSpecific?.luogo || {},
        dimensioni: ctxSpecific?.dimensioni || {},
        immobile: ctxSpecific?.immobile || {},
        marca_bollo: ctxSpecific?.marca_bollo || {},
        ...getExtraFields(ctxSpecific)
    });
    const [values, setValues] = useState({
        ...getValues()
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    /********************* methods go down here *********************/
    ////////////////////// get functions //////////////////////
    const getTowZoneFields = () => {
        let type = null;

        switch(type){
            default:
                return ["richiedente", "tipologia", "chiede", "motivo", "provvedimento", "luogo", "dimensioni", "immobile", "marca_bollo", "preferenze"]
        }
    }

    ////////////////////// validate functions //////////////////////
    const isValidField = (id) => {
        switch(id){
            case "richiedente":
                return data[id]?.length > 0 ? true : false;
            case "provvedimento":
                return (data?.provvedimento?.numero && data?.provvedimento?.data) || (!data?.provvedimento?.numero && !data?.provvedimento?.data) ? true : false
            case "luogo":
                return (data?.luogo?.indirizzo) ? true : false
            case "dimensioni":
                return (data?.dimensioni?.lunghezza && data?.dimensioni?.profondita && data?.dimensioni?.superficie) ? true : false
            case "immobile":
                return (data?.immobile?.foglio && data?.immobile?.particella && data?.immobile?.subalterno && data?.immobile?.categoria) ? true : false
            case "marca_bollo":
                return (data?.marca_bollo?.numero?.length === 14 && data?.marca_bollo?.data) ? true : false
            case "preferenze":
                return true
            default:
                return data[id] ? true : false
        }
    }

    const checkFields = (fields) => {
        // console.log("checkFields", fields)
        if(fields?.length > 0){
            let isValid = true;
            for(let key in fields){
                isValid = typeof isValidField(fields[key]) !== "object" && isValidField(fields[key]) === true;
                // console.log("field", fields[key], isValid)
                if(!isValid) break;
            }
            return isValid;
        }

        return false;
    }

    const isValidData = (isSendRequest) => {
        const tipologia = "";
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(tipologia){
            // case "BA3":
            //     break;
            default:
                if(isSendRequest) requiredFields = getTowZoneFields()
                else requiredFields = []
        }

        if(requiredFields?.length > 0) return checkFields(requiredFields)        

        return true;
    }

    const checkErrors = () => {
        let newError = {...error}

        const fields = getTowZoneFields() || []
        fields.map(field => {
            let isValid = isValidField(field)
            newError[field] = typeof isValid === "object" ? isValid : !isValid
        })
        
        setError(newError);
    }

    const isValidStep = (stepId) => {
        switch(stepId){
            case "general":
                return (error?.richiedente || isStopped) ? false : true
            case "specific":
            case "summary":
                const tenantCode = getTenantCode();
                switch(tenantCode){
                    default:
                        return (
                            error?.richiedente 
                            || error?.tipologia
                            || error?.chiede
                            || error?.motivo
                            // || error?.provvedimento
                            || error?.luogo
                            || error?.dimensioni
                            || error?.immobile
                            || error?.marca_bollo
                            || isStopped) ? false : true
                }
            default:
                return false;
        }
    }
    ////////////////////// UPDATE //////////////////////
    const parseField = (id, newData) => {
        switch(id){
            case "richiedente":
                return newData?.removing ? [] : [{ ...newData?.data?.[0], selected: true }]
            default:
                return newData?.data || null
        }
    }

    const update = (newData) => {
        // console.log("useBonus update", newData, typeof newData === "object", Array.isArray(newData));
        if(typeof newData === "object" && !Array.isArray(newData)){
            const id = newData?.id?.toLowerCase();
            const internalId = newData?.internalId || null;
            if(id){
                if(internalId){
                    setData(oldData => ({
                        ...oldData,
                        [id]: {
                            ...(data?.[id] || {}),
                            [internalId]: newData?.data || null
                        }
                    }))
                }
                else{
                    setData(oldData => ({
                        ...oldData,
                        [id]: parseField(id, newData)
                    }))
                }
            }
        }
        else if (Array.isArray(newData)){
            let updatedData = {...data};
            newData.map(newDataItem => {
                const id = newDataItem?.id?.toLowerCase();
                const internalId = newDataItem?.internalId || null;
                if(id){
                    if(internalId){
                        updatedData = {
                            ...updatedData,
                            [id]: {
                                ...(updatedData?.[id] || {}),
                                [internalId]: newDataItem?.data || null
                            }
                        }
                    }
                    else{
                        updatedData = {
                            ...updatedData,
                            [id]: parseField(id, newDataItem)
                        }
                    }
                }
            })
            // console.log("updatedData", updatedData)
            setData(updatedData);
        }
    }

    const updateField = (e, fieldId, isInternal, internalId) => {
        // console.log("e", e?.target?.id, e?.target?.value);
        const id = internalId || e?.target?.id?.toLowerCase();
        const value = e?.target?.value;
        if((fieldId && id && isInternal) || (fieldId && !isInternal)){
            update({
                id: isInternal ? fieldId : id,
                internalId: isInternal ? id : null,
                data: value
            })
        }
    }

    const updatePrefs = (newPreferences) => {
        // console.log("newPreferences", newPreferences)
        const prefsFields = Object.keys(getPrefs());
        if(prefsFields?.length > 0 && newPreferences?.data?.passo_carrabile){
            let updatedPrefs = {};
            Object.keys(newPreferences?.data?.passo_carrabile)?.map(key => {
                if(prefsFields?.indexOf(key) !== -1)
                    updatedPrefs[key] = newPreferences?.data?.passo_carrabile[key];
            })
            // console.log("new preferences", newPreferences)
            setData({
                ...data,
                ...updatedPrefs
            })
        }
    }
    ////////////////////// SAVE //////////////////////
    const getDefaultPrefs = () => {
        return{
            tipologia: data?.tipologia || "",
            chiede: data?.chiede || "",
            motivo: data?.motivo || "",
            provvedimento: data?.provvedimento || {},
            luogo: data?.luogo || {},
            dimensioni: data?.dimensioni || {},
            immobile: data?.immobile || {},
            marca_bollo: data?.marca_bollo || {},
        }
    }
    const getPrefs = (isSend) => {
        const tenantCode = getTenantCode();
        switch(tenantCode){
            // case "L337":
            //     const shouldShowMarca = values?.extra_declarations ? data?.chiede_extra === values?.extra_declarations?.[3]?.value : false;
            //     return {
            //         ...getDefaultPrefs(),
            //         //// CHIEDE extra ////
            //         chiede_extra: data?.chiede_extra || "",
            //         //// PEC ////
            //         ...((data?.modalita === "Invio tramite PEC" || !isSend) && 
            //         {indirizzo_pec: data?.indirizzo_pec || "",}),
            //         //// MARCA ////
            //         ...((shouldShowMarca || !isSend) && {marca_bollo: data?.marca_bollo || {}}),
            //     }
            default:
                return {
                    ...getDefaultPrefs(),
                }
        }
    }
    const savePrefs = () => {
        // console.log("get prefs", getPrefs())
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: "passo_carrabile", data: {
            ...getPrefs()
        } }));
    }

    const save = async (isSendRequest) => {
        const fields = getTowZoneFields() || []
        let saveData = {}
        fields.map(field => {
            let isValid = typeof isValidField(field) !== "object" && isValidField(field) === true;
            // console.log("is valid data", field, isValid)
            if(data[field] && isValid) saveData[field] = data[field];
        })

        const prefs = getPrefs(true)
        if(Object.keys(prefs)?.length > 0){
            saveData.preferenze = {
                passo_carrabile: {...prefs}
            }
        }

        // console.log("fields", saveData)
        // console.log("save data", saveData)
        let isSend = typeof isSendRequest === "boolean" ? isSendRequest : false;
        if(isValidData(isSend) && ((parentSaveFunc && !isSend) || (parentSendFunc && isSend))){
            dispatcher(stepSlice.actions.dynamicSave({ id: "TowZone", data: {...saveData} }));
            savePrefs();
            setLoading(true);
            if(isSend){
                await parentSendFunc(saveData);
            }
            else{
                await parentSaveFunc(saveData);
            }
            setLoading(false);
        }
    }

    const clear = () => {
        setData({})
    }

    /********************* useEffects go down here *********************/
    useEffect(() => {
        // console.log("useTowZone", data);
        dispatcher(stepSlice.actions.dynamicSave({ id: "TowZone", data: data || {} }));
        savePrefs();
        checkErrors();
    }, [data]);

    useEffect(() => {
        // console.log("useTowZone error", error, data);
    }, [error])

    useEffect(() => {
        checkErrors();
    }, [])

    return {
        data,        // current data of the service request
        values,      // values to populate fields such as dropdowns
        loading,     // true = is saving request 
        error,       // error flag by data[id] eg. data["richiedente"] is invalid -> error.richiedente = true
        getPrefs,
        save,        // function to save the current service request
        savePrefs,   // function to save prefs into the store
        updateField,
        update,      // function to update the data object
        updatePrefs,
        isValidStep, // function to check <stepId> data -> useful for nextButton disable status
    }
}